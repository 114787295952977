<template>
  <div>
    <el-row>
      <el-select v-model="queryInfo.materialSource" class="input" placeholder="备件来源" title="备件来源" clearable filterable>
        <el-option v-for="item in materialSourceList" :key="item.value" :label="item.name" :value="item.value"></el-option>
      </el-select>
      <el-select v-model="queryInfo.sortTestType" clearable placeholder="检验类型" title="检验类型" class="input">
        <el-option label="外观" value="appearance"></el-option>
        <el-option label="电性能" value="electric"></el-option>
      </el-select>
      <el-select v-model="queryInfo.providerStockArea" class="input" placeholder="暂存入库库区" title="暂存入库库区" clearable filterable>
        <el-option v-for="item in providerStockAreaList" :key="item.value" :label="item.name" :value="item.value"></el-option>
      </el-select>
      <el-select v-if="queryInfo.materialSource=='40'" v-model="queryInfo.materialMark" class="input" placeholder="备件标识" title="备件标识" clearable filterable>
        <el-option v-for="item in materialMarkList" :key="item.value" :label="item.name" :value="item.value"></el-option>
      </el-select>
      <el-select v-else-if="queryInfo.materialSource=='120'" v-model="queryInfo.materialMark" class="input" placeholder="索赔复判理由说明" title="索赔复判理由说明" clearable filterable>
        <el-option v-for="item in rmaRejudgeReasonNameList" :key="item.value" :label="item.name" :value="item.value"></el-option>
      </el-select>
      <el-select v-else v-model="queryInfo.materialMark" class="input" placeholder="不可索赔原因" title="不可索赔原因" clearable filterable>
        <el-option v-for="item in noRmaReasonList" :key="item.value" :label="item.name" :value="item.value"></el-option>
      </el-select>
      <el-select v-model="queryInfo.warranty" placeholder="是否保内" title="是否保内" clearable class="input">
        <el-option label="Y" value="Y"></el-option>
        <el-option label="N" value="N"></el-option>
      </el-select>
      <el-select v-model="queryInfo.planCategory" class="input" placeholder="计划大类" title="计划大类" clearable filterable>
        <el-option v-for="item in planCategoryList" :key="item.value" :label="item.name" :value="item.value"></el-option>
      </el-select>
      <el-select v-model="queryInfo.chargeLineCode" class="input" placeholder="运作产线" title="运作产线" clearable filterable>
        <el-option v-for="item in chargeLineCodeList" :key="item.value" :label="item.name" :value="item.value"></el-option>
      </el-select>
      <el-select v-model="queryInfo.electricityResult" placeholder="电性能检验结果" title="电性能检验结果" clearable class="input">
        <el-option label="Y" value="Y"></el-option>
        <el-option label="N" value="N"></el-option>
      </el-select>
      <el-select v-model="queryInfo.appearanceResult" placeholder="外观检验结果" title="外观检验结果" clearable class="input">
        <el-option label="Y" value="Y"></el-option>
        <el-option label="N" value="N"></el-option>
      </el-select>
      <el-select v-model="queryInfo.repairDemand" placeholder="是否有维修需求" title="是否有维修需求" clearable class="input">
        <el-option label="Y" value="Y"></el-option>
        <el-option label="N" value="N"></el-option>
      </el-select>
      <el-select v-model="queryInfo.appearanceRepair" placeholder="外观是否可维修" title="外观是否可维修" clearable class="input">
        <el-option label="Y" value="Y"></el-option>
        <el-option label="N" value="N"></el-option>
      </el-select>
      <el-select v-model="queryInfo.appearanceRenovate" placeholder="外观是否可翻新" title="外观是否可翻新" clearable class="input">
        <el-option label="Y" value="Y"></el-option>
        <el-option label="N" value="N"></el-option>
      </el-select>
      <el-button type="primary" @click="currentChange(1)" :loading="loading">查询</el-button>
      <el-button type="primary" @click="showAdd" :loading="loading">添加</el-button>
      <el-button type="primary" @click="importDialogVisible = true" :loading="loading">全量导入</el-button>
      <el-button type="primary" @click="exportData" :loading="loading">全量导出</el-button>
    </el-row>
    <div class="tip">提示：<br/>
      1、备件标识列：备件来源为"索赔退回"时表示"不可索赔原因"，为"调拨订单"时表示"索赔复判理由说明"，为"备件返还"时表示"备件标识"。<br/>
      2、单元格为空表示该条件忽略，可以匹配任意内容。如果匹配到多条规则，最终将使用排在最前面的一条规则。<br/>
      3、备件来源为采购订单、索赔归还、维修归还、BUFFER免费入库订单(不需归还)、BUFFER免费入库订单(需归还)以及维修退回，不考虑"是否保内"和"是否有维修需求"条件。<br/>
      4、备件来源为"维修退回"、"索赔退回"、"调拨订单"以及"备件返还且备件标识为好件退库、DOA、非损、故障件"的物品，由联想判库。
    </div>
    <el-table :data="ruleList" border stripe style="width: 100%">
      <el-table-column align="center" prop="materialSourceName" label="备件来源" width="120"></el-table-column>
      <el-table-column align="center" prop="sortTestTypeName" label="检验类型" width="70"></el-table-column>
      <el-table-column align="center" prop="providerStockAreaName" label="暂存入库库区"></el-table-column>
      <el-table-column align="center" prop="materialMarkName" label="备件标识" width="70"></el-table-column>
      <el-table-column align="center" prop="warranty" label="是否保内" width="70"></el-table-column>
      <el-table-column align="center" prop="planCategoryName" label="计划大类" width="120"></el-table-column>
      <el-table-column align="center" prop="chargeLineName" label="运作产线" width="100"></el-table-column>
      <el-table-column align="center" prop="electricityResult" label="电性能结果" width="60"></el-table-column>
      <el-table-column align="center" prop="appearanceResult" label="外观结果" width="70"></el-table-column>
      <el-table-column align="center" prop="repairDemand" label="是否有维修需求" width="70"></el-table-column>
      <el-table-column align="center" prop="appearanceRepair" label="外观是否可维修" width="70"></el-table-column>
      <el-table-column align="center" prop="appearanceRenovate" label="外观是否可翻新" width="70"></el-table-column>
      <el-table-column align="center" prop="targetStoreName" label="目标库区"></el-table-column>
      <el-table-column align="center" label="操作" width="150">
        <template slot-scope="scope">
          <el-button @click="showUpdate(scope.row)" size="mini">编辑</el-button>
          <el-button @click="deleteRule(scope.row)" size="mini" type="danger">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="page-box">
      <el-pagination background layout="total,prev, pager, next" :page-size="pageSize" :total="total" @current-change="currentChange" :current-page.sync="queryInfo.pageNo"></el-pagination>
    </div>

    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" :close-on-click-modal="false" width="900px" :before-close="handleClose">
      <el-form v-model="formData" label-width="140px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="备件来源：">
              <el-select v-model="formData.materialSource" class="edit-input" placeholder="备件来源" filterable>
                <el-option v-for="item in materialSourceList" :key="item.value" :label="item.name" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item v-if="formData.materialSource=='40'" label="备件标识：">
              <el-select v-model="formData.materialMark" class="edit-input" placeholder="不可索赔原因" clearable filterable>
                <el-option v-for="item in materialMarkList" :key="item.value" :label="item.name" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="formData.materialSource=='120'" label="索赔复判理由说明：">
              <el-select v-model="formData.materialMark" class="edit-input" placeholder="不可索赔原因" clearable filterable>
                <el-option v-for="item in rmaRejudgeReasonNameList" :key="item.value" :label="item.name" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="formData.materialSource=='50'" label="不可索赔原因：">
              <el-select v-model="formData.materialMark" class="edit-input" placeholder="不可索赔原因" clearable filterable>
                <el-option v-for="item in noRmaReasonList" :key="item.value" :label="item.name" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="检验类型：">
              <el-select v-model="formData.sortTestType" placeholder="检验类型" class="edit-input">
                <el-option label="外观" value="appearance"></el-option>
                <el-option label="电性能" value="electric"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="暂存入库库区：">
              <el-select v-model="formData.providerStockArea" class="edit-input" placeholder="暂存入库库区" clearable filterable>
                <el-option v-for="item in providerStockAreaList" :key="item.value" :label="item.name" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="是否保内：">
              <el-select v-model="formData.warranty" placeholder="是否保内" clearable class="edit-input">
                <el-option label="Y" value="Y"></el-option>
                <el-option label="N" value="N"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="计划大类：">
              <el-select v-model="formData.planCategory" class="edit-input" placeholder="计划大类" clearable filterable>
                <el-option v-for="item in planCategoryList" :key="item.value" :label="item.name" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="电性能检验结果：">
              <el-select v-model="formData.electricityResult" placeholder="电性能检验结果" clearable class="edit-input">
                <el-option label="Y" value="Y"></el-option>
                <el-option label="N" value="N"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="运作产线：">
              <el-select v-model="formData.chargeLineCode" class="edit-input" placeholder="运作产线" clearable filterable>
                <el-option v-for="item in chargeLineCodeList" :key="item.value" :label="item.name" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="外观检验结果：">
              <el-select v-model="formData.appearanceResult" placeholder="外观检验结果" clearable class="edit-input">
                <el-option label="Y" value="Y"></el-option>
                <el-option label="N" value="N"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="是否有维修需求：">
              <el-select v-model="formData.repairDemand" placeholder="是否有维修需求" clearable class="edit-input">
                <el-option label="Y" value="Y"></el-option>
                <el-option label="N" value="N"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="外观是否可维修：">
              <el-select v-model="formData.appearanceRepair" placeholder="外观是否可维修" clearable class="edit-input">
                <el-option label="Y" value="Y"></el-option>
                <el-option label="N" value="N"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="外观是否可翻新：">
              <el-select v-model="formData.appearanceRenovate" placeholder="外观是否可翻新" clearable class="edit-input">
                <el-option label="Y" value="Y"></el-option>
                <el-option label="N" value="N"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="目标库区：">
              <el-select v-model="formData.targetStore" class="edit-input" placeholder="目标库区" filterable>
                <el-option v-for="item in storeTypeList" :key="item.value" :label="item.name" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="addOrUpdate" :loading="loading">确定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="全量导入判库规则" :visible.sync="importDialogVisible" :close-on-click-modal="false" width="800px" :before-close="importHandleClose">
      <ImportComponent template-url="template/ruleJudgeStore.xlsx" upload-url="ruleJudgeStore/upload"/>
      <div slot="footer" class="dialog-footer">
        <el-button @click="importHandleClose">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ImportComponent from "@/components/ImportComponent";

export default {
  name: "RuleJudgeStore",
  components: {ImportComponent},
  data() {
    return {
      queryInfo: {
        materialSource: '',
        sortTestType: '',
        materialMark: '',
        providerStockArea: '',
        warranty: '',
        planCategory: '',
        chargeLineCode: '',
        electricityResult: '',
        appearanceResult: '',
        repairDemand: '',
        appearanceRepair: '',
        appearanceRenovate: '',
        pageNo: 1
      },
      formData: {
        id: '',
        materialSource: '',
        sortTestType: '',
        materialMark: '',
        providerStockArea: '',
        warranty: '',
        planCategory: '',
        chargeLineCode: '',
        electricityResult: '',
        appearanceResult: '',
        repairDemand: '',
        appearanceRepair: '',
        appearanceRenovate: '',
        targetStore: ''
      },
      total: 0,
      pageSize: 0,
      ruleList: [],
      dialogVisible: false,
      importDialogVisible: false,
      loading: false,
      operate: 'add'
    }
  },
  computed: {
    dialogTitle() {
      return this.operate == 'add' ? '添加判库规则' : '修改判库规则';
    },
    materialSourceList() {
      return this.$store.getters.getDictList('materialSource') || [];
    },
    providerStockAreaList() {
      return this.$store.getters.getDictList('providerStockArea') || [];
    },
    materialMarkList() {
      return this.$store.getters.getDictList('materialMark') || [];
    },
    noRmaReasonList() {
      return this.$store.getters.getDictList('noRmaReason') || [];
    },
    rmaRejudgeReasonNameList() {
      return this.$store.getters.getDictList('rmaRejudgeReasonName') || [];
    },
    planCategoryList() {
      return this.$store.getters.getDictList('planCategory') || [];
    },
    chargeLineCodeList() {
      return this.$store.getters.getDictList('chargeLineCode') || [];
    },
    storeTypeList() {
      return this.$store.getters.getDictList('storeType') || [];
    }
  },
  created() {
    this.currentChange(1)
  },
  methods: {
    search() {
      this.loading = true;
      this.$axios.post('ruleJudgeStore/selectPage', this.queryInfo).then(response => {
        this.loading = false;
        let res = response.data;
        if (res.code !== 0) {
          return this.$message.error(res.message);
        }
        this.ruleList = res.data.records;
        this.total = res.data.total;
        this.pageSize = res.data.size;
      }, error => {
        this.loading = false;
        return this.$message.error('查询失败，' + error.message);
      });
    },
    currentChange(pageNo) {
      this.queryInfo.pageNo = pageNo;
      this.search()
    },
    showUpdate(row) {
      this.resetFormData()
      this.operate = 'update'
      this.formData.id = row.id
      this.formData.materialSource = row.materialSource
      this.formData.sortTestType = row.sortTestType
      this.formData.materialMark = row.materialMark
      this.formData.providerStockArea = row.providerStockArea
      this.formData.warranty = row.warranty
      this.formData.planCategory = row.planCategory
      this.formData.chargeLineCode = row.chargeLineCode
      this.formData.electricityResult = row.electricityResult
      this.formData.appearanceResult = row.appearanceResult
      this.formData.repairDemand = row.repairDemand
      this.formData.appearanceRepair = row.appearanceRepair
      this.formData.appearanceRenovate = row.appearanceRenovate
      this.formData.targetStore = row.targetStore
      this.dialogVisible = true
    },
    showAdd() {
      this.operate = 'add'
      this.resetFormData()
      this.dialogVisible = true
    },
    addOrUpdate() {
      if (!this.formData.materialSource) {
        return this.$message.error('备件来源不能为空');
      }
      if (this.formData.materialSource == '40' && !this.formData.materialMark) {
        return this.$message.error('备件标识不能为空');
      } else if (this.formData.materialSource == '120' && !this.formData.materialMark) {
        return this.$message.error('索赔复判理由说明不能为空');
      } else if (this.formData.materialSource == '50' && !this.formData.materialMark) {
        return this.$message.error('不可索赔原因不能为空');
      }

      if (!this.formData.sortTestType) {
        return this.$message.error('检验类型不能为空');
      }
      if (!this.formData.targetStore) {
        return this.$message.error('目标库区不能为空');
      }

      let url = this.operate == 'add' ? 'ruleJudgeStore/add' : 'ruleJudgeStore/update'

      this.loading = true;
      this.$axios.post(url, this.formData).then(response => {
        this.loading = false;
        let res = response.data;
        if (res.code !== 0) {
          return this.$message.error(res.message);
        }

        this.$message.success("保存成功");
        this.currentChange(1)
        this.handleClose()
        this.resetFormData()
      }, error => {
        this.loading = false;
        return this.$message.error('查询失败，' + error.message);
      });
    },
    resetFormData() {
      this.formData.id = ''
      this.formData.materialSource = ''
      this.formData.sortTestType = ''
      this.formData.materialMark = ''
      this.formData.providerStockArea = ''
      this.formData.warranty = ''
      this.formData.planCategory = ''
      this.formData.chargeLineCode = ''
      this.formData.electricityResult = ''
      this.formData.appearanceResult = ''
      this.formData.repairDemand = ''
      this.formData.appearanceRepair = ''
      this.formData.appearanceRenovate = ''
      this.formData.targetStore = ''
    },
    handleClose() {
      this.dialogVisible = false;
    },
    importHandleClose() {
      this.search();
      this.importDialogVisible = false;
    },
    deleteRule(row) {
      this.$confirm('确定删除吗？', '提示', {
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        type: 'warning',
      }).then(() => {
        this.$axios.post('ruleJudgeStore/delete', row.id).then(response => {
          if (response.data.code !== 0) {
            return this.$message.error(response.data.message);
          }
          this.$message.success("删除成功");
          this.search();
        })
      })
    },
    exportData() {
      this.loading = true
      this.$axios.get('ruleJudgeStore/export', {timeout: 600000}).then(response => {
        this.loading = false;
        let res = response.data;
        if (res.code !== 0) {
          return this.$message.error(res.message);
        }
        if (res.data.url) {
          const link = document.createElement('a');
          link.href = res.data.url;
          document.body.appendChild(link);
          link.click();
          URL.revokeObjectURL(link.href);
          document.body.removeChild(link);
        }
      }, error => {
        this.loading = false;
        return this.$message.error('导出失败，' + error.message);
      });

    },
  }
}
</script>

<style scoped>
.input {
  padding-right: 10px;
  padding-bottom: 10px;
  width: 160px;
}

.edit-input {
  width: 250px;
}
</style>